@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: textHelveLight;
    src: url('./fonts/helveticaidesignvn-lt.ttf')
}

@font-face {
    font-family: textHelveMd;
    src: url('./fonts/helveticaidesignvn-md.ttf')
}

@font-face {
    font-family: textHelveRoman;
    src: url('./fonts/helveticaidesignvn-roman.ttf')
}

@font-face {
    font-family: textHelveBold;
    src: url('./fonts/helveticaidesignvn-hv.ttf')
}

#root {
    height: 100%;
}

html {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
}

.font-helve-light {
    font-family: textHelveLight;
}

.font-helve-md {
    font-family: textHelveMd;
}

.font-helve-roman {
    font-family: textHelveRoman;
}

.font-helve-bold {
    font-family: textHelveBold;
}

.text-size-sm {
    font-size: 17px;
}

.logo {
    width: 150px;
}

.text-primary {
    color: #db0000;
}

.line-button-primary {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    border-bottom: 1px solid #d5d5d5;
    &:focus {
        border-color: #db0000;
        border-top: none;
        border-left: none;
        border-right: none;
    }
}

.progress-bar {
    height: 4px;
    background-color: #e6e6e6;
    width: 100%;
    position: relative;
    border-radius: 5px;

    .ingress {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        background-color: #db0000;
        border-radius: 5px;
        transition: all 0.9s;
        width: 0;
    }
}
.grid-cols-2{
    .answer-item {
        padding: 0 20px;
    }
}
.multiple-page {
    .answer-item {
        padding: 0 10px;
        min-height: 48px;
    }
}
.answer-item {
    color: #333;
    border: 1px solid #ccc;
    padding: 0 15px;
    min-height: 50px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px #ccc;
    cursor: pointer;
    transition: all .1s;
    justify-content: center;
    &:hover {
        background-image: linear-gradient(#fd013b, #d71c22);
        color: #fff
    }

    &:checked {
        background-image: linear-gradient(#fd013b, #d71c22);
        color: #fff
    }

    input {
        background-color: transparent;
        border: none
    }

}

.answer-item-input {
    &.active {
        background-image: linear-gradient(#fd013b, #d71c22);
        color: #fff;

        input {
            color: #fff;
            background-color: transparent;
            border-bottom: 1px solid #fff;
            border-radius: 0;
        }
    }

    color: #333;
    border: 1px solid #ccc;
    padding: 0 10px 0 30px;
    min-height: 50px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 60px;
    box-shadow: 0 0 10px #ccc;
    cursor: pointer;
    transition: all .1s;
    justify-content: center;

    input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #ccc;
        width: calc(100% - 100px);
        border-radius: 0;
    }

    span {
        width: 100px;
        display: block;
    }

}

.result-banner {
    width: 260px;
    height: 260px;
    background-size: contain;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;

    .tt {
        font-size: 1.4rem
    }

    .tt-large {
        font-size: 2.4rem
    }
}

.wrap-container {
    width: 830px
}

.peer-checked {
    background-image: linear-gradient(#fd013b, #d71c22);
    border: 1px solid #ccc;
    color: #fff
}

.peer:checked~.peer-checked\:border-blue-600 {
    background-image: linear-gradient(#fd013b, #d71c22);
    border: 1px solid #ccc;
    color: #fff
}

.checkbox:checked~.peer-checked {
    background-image: linear-gradient(#fd013b, #d71c22);
    border: 1px solid #ccc;
    color: #fff
}
.checkbox:not(:checked)~.peer-checked {
    background: #fff;
    border: 1px solid #ccc;
    color: #333
}

.w-1-2 {
    width: 100%;
}

.w-1-3 {
    width: 100%;
}

.result-content {
    max-height: 100%;
    overflow: visible;
    -webkit-mask-image: none;
    transition: max-height 0.15s ease-out;

    &.hid {
        max-height: 68px;
        overflow: hidden;
        -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    }
}

.loadmore {
    height: 30px;
    margin-top: -10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    position: relative;
    cursor: pointer;

    .circle {
        position: absolute;
        left: calc(50% - 15px);
        bottom: -18px;
        display: flex;
        background: #fff;
        width: 30px;
        height: 30px;
        border: 1px solid #333;
        justify-content: center;
        border-radius: 50%;

        svg {
            width: 10px;
        }
    }
}
.more-bottom {
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
    width: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
    z-index: 9999;
    background: #fff;
}
.result-main {
    height: calc(100% - 350px);
    overflow: auto;
}

input[type="radio"]+label span:hover,
input[type="radio"]+label:hover span {
    transform: scale(1.2);
}

input[type="radio"]:checked+label span {
    background-color: #3490DC; //bg-blue
    box-shadow: 0px 0px 0px 2px white inset;
}

.mbtn{
    padding: 14px 20px;
    font-size: 14px;
}
.welcome-page {
    .welcome-banner {
        width: fit-content;
        margin: auto;

        .lb {
            background-color: #e6112c;
            color: #fff;
            padding: 2px 17px;
            border-radius: 20px;
            margin-bottom: 15px;
            margin-left: -20px;
            display: inline-block;
        }

        .image-main {
            height: 200px;
            max-width: 100%;
        }

        .image-left {
            width: 60px;
            top: 78px;
            left: -30px;
            z-index: -1;
        }

        .image-right {
            width: 60px;
            bottom: 40px;
            right: -20px;
        }
    }

    .content {
        width: 100%
    }
}
.quiz-content{
    .it-ans{
        margin: 5px 0;
        padding: 0 10px
    }
    &.xs{
        .answer-item{
            min-height: 55px;
        }
    }
}
select:required:invalid {
    color: gray;
  }
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: black;
  }
@media (min-width: 375px) {
    .mbtn{
        padding: 18px 20px !important;
        font-size: 15px !important;
    }
    .welcome-page {
        .welcome-banner {
            width: fit-content;
            margin: auto;

            .lb {
                background-color: #e6112c;
                color: #fff;
                padding: 2px 17px;
                border-radius: 20px;
                margin-bottom: 15px;
                margin-left: -20px;
                display: inline-block;
            }

            .image-main {
                height: 280px;
                max-width: 100%;
            }

            .image-left {
                width: 70px;
                top: 95px;
                left: -30px;
                z-index: -1;
            }

            .image-right {
                width: 70px;
                bottom: 65px;
                right: -20px;
            }
        }

        .content {
            width: 100%
        }
    }
}

@media (min-width: 414px) {
    .answer-item {
        min-height: 60px;
        font-size:18px;
    }
    .welcome-page {
        .welcome-banner {
            width: fit-content;
            margin: auto;

            .lb {
                background-color: #e6112c;
                color: #fff;
                padding: 2px 17px;
                border-radius: 20px;
                margin-bottom: 15px;
                margin-left: -20px;
                display: inline-block;
            }

            .image-main {
                height: 280px;
                max-width: 100%;
            }

            .image-left {
                width: 80px;
                top: 90px;
                left: -43px;
                z-index: -1;
            }

            .image-right {
                width: 80px;
                bottom: 60px;
                right: -25px;
            }
        }

        .content {
            width: 100%
        }
    }
}

@media (min-width: 640px) {}

@media (min-width: 768px) {
    .answer-item-input{
        min-height: 60px;
    }
    .quiz-content{
        .it-ans{
            margin: 10px 0;
            padding: 0 10px
        }
        &.xs{
            .answer-item{
                min-height: 60px;
            }
        }
    }
    .answer-item {
        min-height: 60px;
        font-size:18px;
    }
    .multiple-page {
        .answer-item {
            padding: 0 20px;
            min-height: 60px;
        }
    }
    .result-main {
        height: auto;
        overflow: auto;
    }

    .loadmore {
        display: none;
    }

    .result-content {
        max-height: 460px;
        overflow: visible;
        -webkit-mask-image: none;

        &.hid {
            max-height: 460px;
            overflow: auto;
            -webkit-mask-image: none
        }
    }

    .w-1-2 {
        width: 50%;
    }

    .w-1-3 {
        width: 33.333333333%;
    }

    .logo {
        width: 160px;
    }

    .welcome-page {
        align-content: baseline;

        .welcome-banner {
            width: fit-content;
            margin: auto;

            .lb {
                background-color: #e6112c;
                color: #fff;
                padding: 2px 17px;
                border-radius: 20px;
                margin-bottom: 15px;
                margin-left: -20px;
                display: inline-block;
            }

            .image-main {
                height: 450px !important;
                max-width: 100%;
            }

            .image-left {
                width: 110px !important;
                top: 130px !important;
                left: -50px !important;
                z-index: -1
            }

            .image-right {
                width: 110px !important;
                bottom: 105px !important;
                right: -25px !important;
            }
        }

        .content {
            width: 400px;
            max-width: 100%;
        }
    }
}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {
    .welcome-page {
        .content {
            width: 480px;
            max-width: 100%;
        }
    }
}

@media (min-width: 1536px) {}

.nextPage{
    .fade-enter {
        opacity: 0;
        transform: translateX(100%);
    }
    
    .fade-enter-active {
        opacity: 1;
        transform: translateX(0%);
    }
    
    .fade-exit {
        opacity: 1;
        transform: translateX(0%);
    }
    
    .fade-exit-active {
        opacity: 0;
        transform: translateX(-100%);
    }
    
    .fade-enter-active,
    .fade-exit-active {
        transition: opacity 300ms, transform 300ms;
    }
}
.prevPage{
    .fade-enter {
        opacity: 0;
        transform: translateX(-100%);
    }
    
    .fade-enter-active {
        opacity: 1;
        transform: translateX(0%);
    }
    
    .fade-exit {
        opacity: 1;
        transform: translateX(0%);
    }
    
    .fade-exit-active {
        opacity: 0;
        transform: translateX(100%);
    }
    
    .fade-enter-active,
    .fade-exit-active {
        transition: opacity 300ms, transform 300ms;
    }
}
